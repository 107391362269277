:root {
  --main-color: #5ea3d0;
  --secondary-color: white;
  --light-secondary-color: Snow;
  --main-text-color: #3e5869;
  --secondary-text-color: #b0c7d6;
  --send-message-form: #cfcfcf63;
  --some-color: ;
  background-color: rgb(240, 240, 240);
}

.App {
  padding: 25px 50px 10px 50px;
  display: grid;
  height: 70%;
  width: 900px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "t t"
    "f r"
    "f r"
    "f r"
    "f r"
    "c a";

  background-color: rgb(240, 240, 240);
}

.Titles {
  grid-area: t;
  padding: 25px 50px 40px 10px;
}

.feed {
  grid-area: f;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  background: var(--secondary-color);
}

.recommendations {
  grid-area: r;
  box-sizing: border-box;
  padding-left: 20px;
  width: 90%;
  height: auto;
  overflow: auto;
  background: var(--secondary-color);
  margin-left: 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.logo {
  width: 5cm;
  padding-top: 20px;
  float: left;
}

.title-item {
  list-style-type: none;
  margin: 0;
  float: right;
  margin-right: 20px;
}

.title-item li {
  display: inline-block;
}

.message-Client {
  width: 90%;
  margin: 10px 0;
  display: inline-block;
  padding: 0 0 0 5px;
  vertical-align: top;
  width: 92%;
}

.message-Client .message-username-Client {
  font-size: 13px;
  color: var(--main-text-color);
  opacity: 0.9;
  margin-bottom: 6px;
}
.message-Client .message-text-Client {
  font-size: 15px;
  background: rgb(221, 221, 221);
  color: #black;
  display: inline;
  padding: 4px 8px;
  border-radius: 8px;
}

.message-Agent {
  margin: 5px 5px 7px 0;
  float: right;
  clear: both;
}

.message-Agent .message-username-Agent {
  font-size: 13px;
  color: var(--main-text-color);
  opacity: 0.9;
  margin: 0 5px 6px 0;
  text-align: right;
}
.message-Agent .message-text-Agent {
  font-size: 15px;
  background: #0696d7;
  color: var(--secondary-color);
  display: inline;
  padding: 4px 8px;
  border-radius: 8px;
}

.send-message-form-agent {
  grid-area: a;
  background: #0696d7;

  display: flex;
  margin: 0 10px 0 50px;
  width: 90%;
  height: 50%;
}
.send-message-form-agent input {
  font-size: 20px;
  width: 100%;
  padding: 15px 10px;
  margin: 0;
  border-style: none;
  background: var(--send-message-form);
  font-weight: 300;
  color: white;
}

.send-message-form-agent input:focus {
  outline-width: 0;
}

.send-message-form-agent input::placeholder {
  color: white;
}
.send-message-form-client {
  grid-area: c;
  background: var(--send-message-form);
  display: flex;
  height: 50%;
}

.send-message-form-client input {
  font-size: 20px;
  width: 100%;
  padding: 15px 10px;
  margin: 0;
  border-style: none;
  background: var(--send-message-form);
  font-weight: 200;
}

.send-message-form-client input:focus {
  outline-width: 0;
}

.send-message-form-client input::placeholder {
  color: black;
}

.help-text {
  position: absolute;
  top: 10px;
}

.button {
  font-size: 15px;
  width: 90%;
  margin: auto;
  border: 1px solid #eeeeee;
  box-shadow: 0 3px 3px #cccccc;
  padding: 15px;
  text-align: left;
  overflow: auto;
  margin-bottom: 20px;
}
.logchat {
  font-size: 15px;
  height: 30%;
  width: 30%;
  border: 1px solid #eeeeee;
  box-shadow: 0 3px 3px #cccccc;
  text-align: center;
  border-radius: 8px;
}
